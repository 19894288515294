import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ROUTE_MAIN, ROUTE_PREMIUM_DEEP_LINK} from 'routes/names';
import {LINK_PREMIUM_DEEPLINK} from "../../config";

export default function MobileAppPagesDeepLink() {
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        const pathname = location.pathname;

        if (pathname === ROUTE_PREMIUM_DEEP_LINK) {
            window.location.href = LINK_PREMIUM_DEEPLINK;
            history.push(ROUTE_MAIN);
            return;
        }

        const matchedDeepLink = pathname.match(/^\/(wallet|staking|swap|premium)(\/.*)?/);

        if (matchedDeepLink) {
            const base = matchedDeepLink[1];
            const rest = matchedDeepLink[2] || '';

            const deepLink = `ime://${base}${rest}`;

            const searchParams = new URLSearchParams(location.search);
            const queryString = searchParams.toString();

            window.location.href = queryString ? `${deepLink}?${queryString}` : deepLink;
            history.push(ROUTE_MAIN);
        } else {
            history.push(ROUTE_MAIN);
        }
    }, [location, history]);

    return null;
}
